<template>
  <div>
    <v-dialog v-model="UserAccessdialog" width="400">
              <v-card   flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                      {{editedUser.Full_Name}} Permissions
                  </v-card-title>
                  <v-card-text>
                      <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-text>
                             <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="AdvanceDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                            :UsersLookupArray="NonInteractingUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                            />
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                        <v-tabs v-model="PermissionTab" centered>
                          <v-tab>CRUD</v-tab>
                          <v-tab-item>
                      <v-list  v-if="editedUser && editedUser.Warehouses">
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(0,2)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(2,4)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(4,6)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" :disabled="action.DisablingFalsePeer ? !editedUser.Warehouses[SelectedWarehouse.id][action.DisablingFalsePeer] : false" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                    </v-list>
                          </v-tab-item>
                     
                          <v-tab>Moderation</v-tab>
                          <v-tab-item>
                      <v-list  v-if="editedUser && editedUser.Warehouses">
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(0,2)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(2,4)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(4,6)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(6,8)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(8,10)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[SelectedWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                    </v-list>
                          </v-tab-item>
                      </v-tabs>
                        </v-card-text>
                    </v-window-item>
                    </v-window>
                      
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseUserDialog()">
                          Cancel
                      </v-btn>
                    <!-- <v-btn
                        :disabled="step === 1"
                        text
                        @click="step--"
                    >
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!editedUser.id"
                        color="primary"
                        depressed
                        @click="step++"
                    >
                        Next
                    </v-btn> -->
                    </v-card-actions>
              </v-card>
          </v-dialog>
    <v-tabs centered>
      <v-tab>Warehouse</v-tab>
      <v-tab-item>
        <v-card >
       <v-select clearable @change="UpdateAppProp('Default_Warehouse')" item-text="Name" label="Default Warehouse" :items="ActiveAppWarehouses" v-model="ActiveSuiteApp.Default_Warehouse" return-object/>
          <v-card-title class="background darken-1 mediumoverline justify-center">
             Business Units
          </v-card-title>
          <v-card-text>
             <v-window v-model="bueditstep">
              <v-window-item :value="1">
                <v-row class="background justify-center my-6">
                <div v-for="bu in BusinessUnitsArray" :key="bu.itemObjKey" style="padding: 20px;" class="flex xl3 lg3 md4 sm6 xs12">
                  <v-card @click="SelectBU(bu)" height="200">
                      <v-card-title class="overline">                     
                            {{bu.Name}}
                            <v-spacer>
                            </v-spacer>
                            <v-icon>mdi-security-network
                          </v-icon>
                      </v-card-title>
                      <v-card-text style="text-align: center;" class="caption" v-html="bu.Description">
                      </v-card-text>
                  </v-card>
              </div>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-title v-if="ActiveBU"><v-btn @click="bueditstep--,SelectedWarehouse = ''">Back</v-btn><v-spacer>
                    </v-spacer>
                  {{ActiveBU.Name}} <v-spacer>
                    </v-spacer><v-btn @click="bueditstep++">View Warehouses</v-btn>
                </v-card-title>
                <v-card-text v-if="ActiveBU">
                   <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="ActiveBU" :QuickEditSections="BUQuickEditSections"
                    @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
                    />
                </v-card-text>
                <v-card-actions>
                  <v-spacer>
                  </v-spacer>
                  <v-btn dark color="success" @click="UpdateBU()">Update</v-btn>
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="3">
                <v-card-title class="justify-center" v-if="ActiveBU"><v-btn @click="bueditstep--">Back</v-btn><v-spacer>
                    </v-spacer>
                   {{ActiveBU.Name}} Warehouses
                </v-card-title>
                <v-card-text v-if="ActiveBU">
                  <v-row class="background justify-center my-6">
                <div v-for="wh in ActiveBUWarehouses" :key="wh.itemObjKey" style="padding: 20px;" class="flex xl3 lg3 md4 sm6 xs12">
                    <v-card @click="SelectWH(wh)"  height="200">
                        <v-card-title class="overline">                     
                              {{wh.Name}}
                              <v-spacer>
                              </v-spacer>
                              <v-icon>mdi-warehouse
                            </v-icon>
                        </v-card-title>
                        <v-card-text style="text-align: center;" class="caption" v-html="wh.Description">
                        </v-card-text>                    
                    </v-card>
                </div>
                  </v-row>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="4">
                <v-card-title class="justify-center" v-if="SelectedWarehouse">
                  <v-btn @click="bueditstep--">Back</v-btn><v-spacer>
                    </v-spacer>
                   {{ActiveBU.Name}} - {{SelectedWarehouse.Name}} <v-spacer>
                    </v-spacer>
                    <!-- <v-btn @click="bueditstep++">View Warehouses</v-btn> -->
                </v-card-title>
                <v-card-text v-if="SelectedWarehouse">
                   <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="SelectedWarehouse" :QuickEditSections="WHQuickEditSections"
                    @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
                    />
                </v-card-text>
                <v-card-text v-if="SelectedWarehouse">
                    <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenUserDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                :UsersLookupArray="InteractingUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="true" @AddMethod="OpenNEWUserDialog"
                
                />
                <v-autocomplete multiple item-text="id" label="Transaction Notification Party" :items="UsersArray" v-model="SelectedWarehouse.TransactionNotificationParty"
                @change="UpdateTransactionNotificationParty()"
                >
                <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.Profile_Photo"></v-img>
                  </v-avatar>
                  {{ data.item.Full_Name }}
                </v-chip>
              </template>
                 <template v-slot:item="data">
                    <v-list-item-avatar>
                    <img :src="data.item.Profile_Photo">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.Email"></v-list-item-subtitle>
                  </v-list-item-content>
                  </template>
                </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer>
                  </v-spacer>
                  <v-btn dark color="success" @click="UpdateWH()">Update</v-btn>
                </v-card-actions>
              </v-window-item>
             </v-window>
          </v-card-text>
     </v-card>
          <v-card >
          <v-card-title class="background darken-1 mediumoverline justify-center">
             Available Plugins
          </v-card-title>
           <v-list>
        <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General'])" :tab="systemguideelmnt"
        :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
            />
          </v-list>
          </v-card>
      </v-tab-item>
      <v-tab>Database</v-tab>
      <v-tab-item>
        
    <DatabaseConfig :System="System" :SystemEntities="SystemEntities" :RADB="RADB" :Directories="Directories"
    :SystemGuides="SystemGuides" :ActiveSession="{title: 'Warehouses'}"
            />
      </v-tab-item>
    </v-tabs>
     
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import QuickEditComponent from '@/components/Database/Fields/Input/QuickEditComponent'
import DatabaseConfig from '@/components/SuiteBuilder/SuiteApps/Settings/Database'
import UserLookupTable from '@/components/General/UserLookupTable'

export default {
    props: ['System','SystemEntities','RADB','Directories','ActiveSession','SystemGuides'],
    components: {RAPluginCarousel,QuickEditComponent,DatabaseConfig,UserLookupTable},
    data() {
        return {
          step: 1,
          PermissionTab: 0,
          editedUserIndex: -1,
            editedUser: {

            },
            defaulteditedUser: {

            },
            UserAccessdialog: false,
            UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'}
            ],
          WarehouseActions: [
        {Display: 'Allocating',Prop: 'Allocating',List: 'Warehouse Transactions'},
        {Display: 'Requisitions',Prop: 'Requisitions',List: 'Warehouse Transactions'},
        {Display: 'Ordering',Prop: 'Ordering',List: 'Warehouse Transactions'},
        {Display: 'Receiving',Prop: 'Receiving',List: 'Warehouse Transactions'},
        {Display: 'Receiving Priorities',Prop: 'Receiving_Priorities',List: 'Warehouse Transactions',DisablingFalsePeer: 'Receiving'},
        {Display: 'Picking',Prop: 'Picking',List: 'Warehouse Transactions'},
        {Display: 'Packing',Prop: 'Packing',List: 'Warehouse Transactions'},
        {Display: 'Dispatching',Prop: 'Dispatching',List: 'Warehouse Transactions'},
        {Display: 'Auditing',Prop: 'Auditing',List: 'Warehouse Transactions'},        
        {Display: 'Create',Prop: 'Create',List: 'Warehouse Products'},
        {Display: 'Get',Prop: 'Get',List: 'Warehouse Products'},
        {Display: 'List',Prop: 'List',List: 'Warehouse Products'},
        {Display: 'Update',Prop: 'Update',List: 'Warehouse Products'},
        {Display: 'Delete',Prop: 'Delete',List: 'Warehouse Products'},
        {Display: 'Publish',Prop: 'Publish',List: 'Warehouse Products',DisablingFalsePeer: 'Update'},
      ],
          UsersArrayChecked: [],
            UsersReady: false,
          bueditstep: 1,
          ActiveBU: '',
          SelectedWarehouse: '',
          systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            Bill_To_Address: {id: 'Bill_To_Address',Name: 'Bill_To_Address',DisplayName: 'Bill To Address',propvalue: 'Bill_To_Address',text: 'Bill To Address',value: 'Bill_To_Address',class: 'overline',Type: 'Common Field',CommonFieldType: 'Postal Address',
                FieldBreakdown: []},
            Delivery_Address: {id: 'Delivery_Address',Name: 'Delivery_Address',DisplayName: 'Delivery Address',propvalue: 'Delivery_Address',text: 'Delivery Address',value: 'Delivery_Address',class: 'overline',Type: 'Common Field',CommonFieldType: 'Physical Address',
                FieldBreakdown: []},
        }
    },	
    computed:{
      UsersArray(){
            return this.$store.state.UsersArray
        },
        
        UsersArrayPrepared(){
            return this.UsersReady ? this.UsersArrayChecked.map(user => {
                let counter = 0
                this.WarehouseActions.map((act,actindex) => {
                    if(!user.Warehouses[this.SelectedWarehouse.id]){
                        user.Warehouses[this.SelectedWarehouse.id] = {}
                    }
                    if(typeof user.Warehouses[this.SelectedWarehouse.id][act.Prop] === 'undefined'){
                        user.Warehouses[this.SelectedWarehouse.id][act.Prop] = false
                    }
                    else if(user.Warehouses[this.SelectedWarehouse.id][act.Prop]){
                        counter = counter-1+2
                        user.Pass = true
                    }
                    if(actindex-1+2 === this.WarehouseActions.length && counter === 0){
                        
                        user.Pass = false
                    }
                })
                return user
            }) : []
        },
        InteractingUsers(){
            return this.UsersArrayPrepared.filter(user => {
                return user.Warehouses[this.SelectedWarehouse.id] && user.Pass
            })
        },
      NonInteractingUsers(){
            return this.UsersArrayPrepared.filter(user => {
                return user.Warehouses[this.SelectedWarehouse.id] && !user.Pass
            })
        },
      BUFields(){
        return [
          {id: 'Company_Name',Name: 'Company_Name',DisplayName: 'Company Name',propvalue: 'Company_Name',text: 'Company Name',value: 'Company_Name',class: 'overline',Type: 'Single Line Text'},
          {id: 'Tax_Percentage',Name: 'Tax_Percentage',DisplayName: 'Tax Percentage',propvalue: 'Tax_Percentage',text: 'Tax Percentage',value: 'Tax_Percentage',class: 'overline',Type: 'Number Field',IsPercentage: true},
          this.Bill_To_Address,
          {id: 'Description',Name: 'Description',DisplayName: 'Description',propvalue: 'Description',text: 'Description',value: 'Description',class: 'overline',Type: 'Multiple Lines Text'},
        ]
      },
      BUQuickEditSections(){
        let ent = {
          AllFields: this.BUFields
        }
          let sect1fields = ['Company_Name','Description','Tax_Percentage']
          let sect2fields = ['Bill_To_Address']
          console.log(ent)
          return [
            {Header: 'Company',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields,ent,this.ActiveBU),Class: 'flex xl6 lg6 md12 sm12 xs12'},
            {Header: 'Address',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields,ent,this.ActiveBU),Class: 'flex xl6 lg6 md12 sm12 xs12'},
          ]
      },
      WHFields(){
        return [
          {id: 'Is_Public',Name: 'Is_Public',DisplayName: 'Is Public',propvalue: 'Is_Public',text: 'Is Public',value: 'Is_Public',class: 'overline',Type: 'Boolean'},
          {id: 'Guests_Allowed',Name: 'Guests_Allowed',DisplayName: 'Guests Allowed',propvalue: 'Guests_Allowed',text: 'Guests Allowed',value: 'Guests_Allowed',class: 'overline',Type: 'Boolean'},
          {id: 'Company_Name',Name: 'Company_Name',DisplayName: 'Company Name',propvalue: 'Company_Name',text: 'Company Name',value: 'Company_Name',class: 'overline',Type: 'Single Line Text'},
          {id: 'Tax_Percentage',Name: 'Tax_Percentage',DisplayName: 'Tax Percentage',propvalue: 'Tax_Percentage',text: 'Tax Percentage',value: 'Tax_Percentage',class: 'overline',Type: 'Number Field',IsPercentage: true},
          this.Delivery_Address,
          {id: 'Description',Name: 'Description',DisplayName: 'Description',propvalue: 'Description',text: 'Description',value: 'Description',class: 'overline',Type: 'Multiple Lines Text'},
        ]
      },
      WHQuickEditSections(){
        let ent = {
          AllFields: this.WHFields
        }
          let sect1fields = ['Company_Name','Description','Guests_Allowed','Is_Public']
          let sect2fields = ['Delivery_Address']
          console.log(ent)
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields,ent,this.ActiveBU),Class: 'flex xl6 lg6 md12 sm12 xs12'},
            {Header: 'Address',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields,ent,this.ActiveBU),Class: 'flex xl6 lg6 md12 sm12 xs12'},
          ]
      },
      WarehousesArray(){
        return this.$store.state.WarehousesArray
      },
      ActiveAppWarehouses(){
        return this.WarehousesArray.filter(wh => {
          return wh.Primary_Appid === this.ActiveSuiteApp.id
        }).map(wh => {
          let whobj = {
            id: wh.id,
            Business_Unitid: wh.Business_Unitid,
            Business_Unit: wh.Business_Unit,
            Name: wh.Name
          }
          return whobj
        })
      },
      ActiveBUWarehouses(){
        return this.ActiveBU ? this.WarehousesArray.filter(wh => {
          return wh.Business_Unitid === this.ActiveBU.id && wh.Primary_Appid === this.ActiveSuiteApp.id
        }) : []
      },
      RAPlugins(){
            return this.$store.state.RAPlugins
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
        StoreEntity(){
          return this.AppEntities.find(obj => obj.Entity_Type.Name === 'Store Product')
        },
        BusinessUnitsArray(){
            return this.$store.state.BusinessUnitsArray
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        AppsPath(){
        return this.$store.state.AppsPath
        },
        ProductsInteractions(){
            return this.WarehouseActions.filter(act => {
                //act.ModeratorProp = act.Prop+'Clerks'
                return act.List === 'Warehouse Products'
            })
        },
        TransactionalWarehouseActions(){
            return this.WarehouseActions.filter(act => {
                //act.ModeratorProp = act.Prop+'Clerks'
                return act.List === 'Warehouse Transactions'
            })
        },
    },
    watch: {
      SelectedWarehouse(v){
        if(v){
          this.PrepareWarehouseUsers()
        }
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.$store.commit('setStoreEntity',this.StoreEntity)
      this.PrepareAddressField('Bill_To_Address')
      this.PrepareAddressField('Delivery_Address')
      
    },
    methods:{
      remove (item) {
        console.log(item)
        const index = this.SelectedWarehouse.TransactionNotificationParty.indexOf(item.id)
        if (index >= 0){
          this.SelectedWarehouse.TransactionNotificationParty.splice(index, 1)
        }
        this.UpdateTransactionNotificationParty()
      },

      UpdateTransactionNotificationParty(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.SelectedWarehouse.id).update({
          TransactionNotificationParty: this.SelectedWarehouse.TransactionNotificationParty
        })
      },
      PrepareWarehouseUsers(){
        this.UsersArrayChecked = this.UsersArray.map((user,userindex) => {
            this.GetUserRolesDoc(user).then(result => {
                user.Warehouses = result
                if(userindex-1+2 === this.UsersArray.length){
                    console.log('result',result)
                    this.UsersReady = true
                }
            })
            return user
        })
      },
      UpdateWarehousePermissions(action){
        //console.log(action,this.editedUser,this.InteractingUsers)
         this.UsersReady = false
         if(!this.editedUser.Warehouses[this.SelectedWarehouse.id].Update){
             this.editedUser.Warehouses[this.SelectedWarehouse.id].Publish = false
         }
         db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.editedUser.id).update({
            Warehouses: this.editedUser.Warehouses
            })
            setTimeout(() => {
                this.UsersReady = true
            }, 50);
        },
        OpenNEWUserDialog(){
            
            this.step = 1
            this.editedUser = Object.assign({},this.defaulteditedUser)
            this.editedUserIndex = -1
            this.UserAccessdialog = true
        },
      OpenUserDialog(user){
            this.PermissionTab = 0
            this.editedUser = user
            this.editedUserIndex = this.InteractingUsers.indexOf(user)
            this.step = 2
            this.UserAccessdialog = true
            
        },
      CloseUserDialog(){
            this.editedUser = Object.assign({},this.defaulteditedUser)
            this.UserAccessdialog = false
        },
        AdvanceDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.NonInteractingUsers.indexOf(user)
            this.step++
        },
        GetUserRolesDoc(user){
            return new Promise(function(resolve, reject) {
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(user.id).onSnapshot(snapshot => {
                    let userroledoc = snapshot.data()
                    if(userroledoc.Warehouses){
                        resolve(userroledoc.Warehouses)     
                    }
                    else{
                        resolve({})
                    }                    
                })
            })
        },
      UpdateAppProp(prop){
        let updateobj = {
            [prop]: this.ActiveSuiteApp[prop]
          }
        if(prop === 'Default_Warehouse'){
          if(this.ActiveSuiteApp[prop]){            
            updateobj.Default_Warehouseid = this.ActiveSuiteApp[prop].id
          }
          else{
            updateobj.Default_Warehouseid = ''
          }
        }
        this.AppsPath.doc(this.ActiveSuiteApp.id).update(updateobj)
      },
      UpdateBU(){
        let buobj = {}
        let fields = this.BUQuickEditSections.map(sect => {
          return sect.Fields
        }).flat()
        fields.map(field => {
          if(typeof this.ActiveBU[field.Name] !== 'undefined'){
            buobj[field.Name] = this.ActiveBU[field.Name]
          }
          else if(field.CommonFieldType.includes('Address')){
            field.FieldBreakdown.map(brk => {
              if(typeof this.ActiveBU[brk.Name] !== 'undefined'){
                buobj[brk.Name] = this.ActiveBU[brk.Name]
              }
            })
          }
        })
        console.log(buobj)
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(this.ActiveBU.id).update(buobj)
      },
      UpdateWH(){
        let whobj = {}
        let fields = this.WHQuickEditSections.map(sect => {
          return sect.Fields
        }).flat()
        fields.map(field => {
          if(typeof this.SelectedWarehouse[field.Name] !== 'undefined'){
            whobj[field.Name] = this.SelectedWarehouse[field.Name]
          }
          else if(field.CommonFieldType.includes('Address')){
            field.FieldBreakdown.map(brk => {
              if(typeof this.SelectedWarehouse[brk.Name] !== 'undefined'){
                whobj[brk.Name] = this.SelectedWarehouse[brk.Name]
              }
            })
          }
        })
        console.log(whobj)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.SelectedWarehouse.id).update(whobj)
      },
      PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
      PrepQuickFieldSection(fields,entity,record){
        return fields.map(field => {
            let fieldobj = Object.assign({},entity.AllFields.find(obj => obj.id === field))
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof record[fieldobj.Name] !== 'undefined' && typeof record[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = record[fieldobj.Name].toDate()
                record[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            return fieldobj
          })
      },
      SelectWH(wh){
        this.SelectedWarehouse = wh
        this.bueditstep++
      },
      SelectBU(bu){
        this.ActiveBU = bu
        this.bueditstep++
      },
      CancelActiveBU(){
        this.ActiveBU = ''
      },
      PickPlugin(plug){
            this.$emit('PickPlugin',plug)
        },
      RAPluginsMatched(categorymatches){
        let whextensions = []
        let whplug = this.RAPlugins.find(obj => obj.id === 'Warehouse_&_Products')
        if(whplug && whplug.Extensions){
          whextensions = whplug.Extensions.map(ext => {
            return ext.id
          })
        }
        //console.log(whextensions)
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        }).filter(plug => {
          return whextensions.includes(plug.id)
        })
        },
        ActivateActiveEntity(ent){
            this.ActiveEntity = ent
        },
    }
}
</script>

<style>

</style>



